<template>
  <Layout>
    <div class="container-fluid pt-4">
      <div class="row">
        <div class='col'>
          <h5 class="page-header">
            Edit Tag Type: {{ editingTagType.name }}
          </h5>
        </div>
      </div>
      <hr class="dotted-spacer" />
      <!-- Loading spinner -->
      <div
        v-if="$apollo.loading"
        class="position-fixed end-0 w-100 justify-content-center"
        style="background: rgba(255, 255, 255, 0.8); height: 100vh; z-index: 1050"
      >
        <div class="text-center col pt-5 mt-5">
          <BaseSpinner />
          <p class="d-block lead fs-4 mt-5">Loading tag data</p>
        </div>
      </div>
      <div v-else class='row px-5 pt-4'>
        <div class="col col-md-8 col-lg-6 col-xl-4">
          <ValidationObserver ref='form' v-slot="{  }" >
            <form @submit.prevent="updateTagType">
              <h5>{{ editingTagType ? editingTagType.label : "Loading" }}</h5>
              
              <BaseAlert v-if="alert.type" class="mb-4" :type="alert.type">
                <span slot="title">{{ alert.title }}</span>
                <ul v-if="typeof(alert.message) === 'object'" slot="message">
                  <li v-for="m in alert.message" :key="m">{{ m }}</li>
                </ul>
                <span v-else slot="message">{{ alert.message }}</span>
              </BaseAlert>


              <BaseFormGroup :label="'Name'" >
                <ValidationProvider v-slot="{ errors }" name='Tag Name' rules='required' >
                  <BaseInput v-model="editingTagType.label" type="text" name="name" :error="errors[0]" />
                </ValidationProvider>
              </BaseFormGroup>

              <BaseFormGroup :label="'Colour'" >
                <ValidationProvider name='Tag Colour'>
                  <!-- <BaseMultiselect v-model="editingTagType.color" :options="availableColors" :error="errors[0]" name="color" /> -->
                  <BaseColorPicker v-model="editingTagType.color" />
                </ValidationProvider>
              </BaseFormGroup>

              <BaseFormGroup class=" mt-5 mx-5">
                <BaseButton type="submit" :disabled="isLoading" :classes="'btn-success'" >
                  <span>Save</span>
                </BaseButton>

                <BaseButton class="btn-secondary mx-5 px-4" :disabled="isLoading" type="button" @click="$router.push({name: 'tags'})">
                  <span>Back</span>
                </BaseButton>
              </BaseFormGroup>
            </form>
          </ValidationObserver>
        </div>
      </div>

      <div v-if="user.role.name === 'SYSADMIN'" class="row mt-5">
        <div class="col">
          <div class='card'>
            <div class='card-body bg-light'>
              <h5 class="card-title">SYSADMIN debug</h5>
              <p class='card-text'>
                User:
                <pre><code class="text-break">{{ editingTagType }}</code></pre>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>


<script>
import Layout from '@layouts/main'
import {
  LocalGetSelf,
} from '@gql/user'
import {
  GetTagType,
  UpdateTagType
} from '@gql/tagTypes'
import {
  ValidationProvider,
  ValidationObserver
} from 'vee-validate'

export default {
  page() {
    const name = this.editingTagType ? this.editingTagType.name : 'Tag'

    return {
      title: name,
      meta: [
        {
          name: 'description',
          content: `Edit ${name}.`,
        },
      ],
    }
  },
  components: { Layout, ValidationProvider, ValidationObserver },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      alert: {},
      editingTagType: {
        color: null,
        name: null,
        label: null
      },
    }
  },
  apollo: {
    user: {
      query: LocalGetSelf,
      update(data) {
        if(!data.user.isAdminUser) { this.$router.push({ name: '403', params: { resource: 'tags.edit' }}) }
        return data.user
      }
    },
    editingTagType: {
      query: GetTagType,
      variables() {
        return { id: this.id }
      },
      update: (data) => (data.tagType),
      result({ data: { tagType } }) {
        console.log(tagType)
        if (!tagType) {
          this.$router.push({ name: '404', params: { resource: 'TagType' } })
        }
      },
      fetchPolicy: 'no-cache'
    },
  },
  methods: {
    async updateTagType() {
      this.isLoading = true
      this.alert = {}

      // Validate the form
      if(!await this.$refs.form.validate()) {
        this.isLoading = false
        this.alert.type = 'error'
        this.alert.title = 'Error'
        this.alert.message = 'Please check the form below for errors'
        return false
      }
      // Validation good, reset
      this.$refs.form.reset()

      this.$apollo.mutate({
        mutation: UpdateTagType,
        variables: {
          id: this.id,
          data: {
            label: this.editingTagType.label,
            color: this.editingTagType.color
          }
        },
        update: (store, { data: { updateTagType }}) => {},
        error (err) {
          this.$log.error(err)
          this.alert.type = 'error'
          this.alert.title = 'There was a problem'
          this.alert.message = err
        },
      }).then(data => {
        this.alert.type = 'success'
        this.alert.title = 'Success'
        this.alert.message = 'TagType updated'
        this.isLoading = false
        this.$refs.form.reset()
        // Redirect
        setTimeout(() => { this.$router.push({name: 'tags'}) }, 1000)
      }).catch((err) => {
        // Error
        this.alert.type = 'error'
        this.alert.title = 'There was a problem'
        this.alert.message = err
      })
    },
  }
}
</script>